import React from 'react';
import JsonBeautifier from './components/JsonBeautifier';
import FAQ from './components/FAQ';
import SeoContent from './components/SeoContent';
import './App.css';
function App() {
  return (
    <div className="App">
      <header>
        <h1>JSON Beautifier and Formatter</h1>
      </header>
      <main>
        <JsonBeautifier />
        <FAQ />
        <SeoContent />
      </main>
      <footer>
        <p>&copy; 2024 JSON Beautifier and Formatter. All rights reserved.</p>
      </footer>
    </div>
  );
}
export default App;