import React from 'react';

function FAQ() {
  return (
    <div className="faq">
      <h2>Frequently Asked Questions</h2>
      <details>
        <summary>What is JSON?</summary>
        <p>JSON (JavaScript Object Notation) is a lightweight data interchange format that is easy for humans to read and write and easy for machines to parse and generate.</p>
      </details>
      <details>
        <summary>Why use a JSON beautifier?</summary>
        <p>A JSON beautifier helps format and indent JSON data, making it more readable and easier to understand, especially for large or complex JSON structures.</p>
      </details>
      <details>
        <summary>Can I use single quotes in my JSON input?</summary>
        <p>Yes, our beautifier supports JSON-like strings with either single or double quotes. It will convert single quotes to double quotes before parsing and formatting.</p>
      </details>
      <details>
        <summary>Is my data safe when using this tool?</summary>
        <p>Absolutely. All processing is done in your browser, and we don't store or transmit any of your data.</p>
      </details>
    </div>
  );
}

export default FAQ;