import React, { useState, useRef, useEffect } from 'react';
function JsonBeautifier() {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');
  const outputRef = useRef(null);
  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);
  const beautifyJson = () => {
    try {
      const jsonString = input.replace(/'/g, '"');
      const parsedJson = JSON.parse(jsonString);
      const beautifiedJson = JSON.stringify(parsedJson, null, 4);
      setOutput(beautifiedJson);
      setError('');
    } catch (err) {
      setError('Invalid JSON input. Please check your JSON string.');
      setOutput('');
    }
  };
  return (
    <div className="json-beautifier">
      <div className="input-container">
        <h2>Input JSON</h2>
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Paste your JSON-like string here..."
        />
      </div>
      <button onClick={beautifyJson}>Beautify JSON</button>
      {error && <p className="error">{error}</p>}
      <div className="output-container" ref={outputRef}>
        <h2>Beautified JSON</h2>
        <pre>{output}</pre>
      </div>
    </div>
  );
}
export default JsonBeautifier;